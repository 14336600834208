import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { fetchSubtitles } from '../actions/fetchActions'
import AppBarComponent from './AppBarComponent'
import SelectionScreen from './screens/SelectionScreen'
import DashboardScreen from './screens/DashboardScreen'
import FooterComponent from './layout/FooterComponent'
import { useAuth } from '../contexts/AuthContext'

const MapWithVariable = () => {
  const dispatch = useDispatch()
  const { keycloak } = useAuth()
  const subtitles = useSelector((state) => state.subTitles?.subtitles || [])

  const [selectedVariable, setSelectedVariable] = useState('')
  const [selectedSubtitle, setSelectedSubtitle] = useState('Select an Event')
  const [selectedId, setSelectedId] = useState(null)

  useEffect(() => {
    if (keycloak?.token) {
      dispatch(fetchSubtitles(keycloak.token))
    }
  }, [dispatch, keycloak?.token])

  const handleSelectionChange = (e) => {
    const selectedName = e.target.value
    setSelectedVariable(selectedName)
    const selectedSubtitleObject = subtitles.find(
      (subtitle) => subtitle.name === selectedName
    )
    setSelectedSubtitle(
      selectedSubtitleObject?.description || 'Select an Event'
    )
    setSelectedId(selectedSubtitleObject?.id || null)
  }

  const handleBackButtonClick = () => {
    setSelectedVariable('')
    setSelectedSubtitle('Select an Event')
    setSelectedId(null)
  }

  const handleLogout = () => {
    keycloak?.logout()
  }

  if (!selectedId) {
    return (
      <SelectionScreen
        value={selectedVariable}
        onChange={handleSelectionChange}
        subtitles={subtitles}
      />
    )
  }

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBarComponent
        subtitle={selectedSubtitle}
        username={keycloak?.tokenParsed?.preferred_username}
        onLogout={handleLogout}
      />
      <DashboardScreen
        selectedId={selectedId}
        onBack={handleBackButtonClick}
        token={keycloak?.token}
      />
      <FooterComponent />
    </Box>
  )
}

export default MapWithVariable
