import React from 'react'
import {
  Container,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Grid,
} from '@mui/material'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'
import { GlobalStyles } from '@mui/material'

const SelectionScreen = ({ value, onChange, subtitles = [] }) => {
  const colors = {
    slate: '#6E8CA0',
    whoop: '#500000',
    rain: '#324755',
    sage: '#87BCBF',
    snow: '#FFFFFF',
    onyx: '#1B1C20',
    sunshine: '#FCC931',
    ice: '#F0F3F4',
    sand: '#B9B0A2',
  }

  const features = [
    { icon: AnalyticsOutlinedIcon, label: 'Advanced Analytics' },
    { icon: SecurityOutlinedIcon, label: 'Secure Assessment' },
    { icon: SpeedOutlinedIcon, label: 'Real-time Processing' },
  ]

  const DashboardCard = ({ children, title, minHeight }) => (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 3,
        background: `${colors.onyx}95`,
        backdropFilter: 'blur(20px)',
        border: `1px solid ${colors.snow}15`,
        height: '100%',
        minHeight: minHeight || 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ p: { xs: 3, md: 4 }, height: '100%' }}>
        {title && (
          <Typography
            variant="h6"
            sx={{
              color: colors.snow,
              mb: 3,
              fontWeight: 500,
            }}
          >
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </Paper>
  )

  return (
    <>
      <GlobalStyles
        styles={{
          html: {
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          },
          body: {
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          },
          '#root': { width: '100%', height: '100%' },
        }}
      />
      <Box
        sx={{
          minHeight: '100vh',
          background: colors.rain,
          position: 'relative',
          overflow: 'auto',
          py: 4,
        }}
      >
        {/* Animated Background */}
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.7,
            background: `linear-gradient(135deg, ${colors.rain} 0%, ${colors.onyx} 100%)`,
            zIndex: 0,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '5%',
              left: '5%',
              width: '40%',
              height: '40%',
              background: `radial-gradient(circle, ${colors.sage}20 0%, transparent 70%)`,
              filter: 'blur(50px)',
              animation: 'pulse 8s ease-in-out infinite',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '5%',
              right: '5%',
              width: '40%',
              height: '40%',
              background: `radial-gradient(circle, ${colors.whoop}20 0%, transparent 70%)`,
              filter: 'blur(50px)',
              animation: 'pulse 8s ease-in-out infinite alternate',
            },
          }}
        />

        <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 1 }}>
          {/* Header */}
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Box
              sx={{
                display: 'inline-flex',
                p: 2,
                borderRadius: '50%',
                background: `linear-gradient(135deg, ${colors.slate}30, ${colors.whoop}20)`,
                border: `1px solid ${colors.snow}15`,
                mb: 3,
              }}
            >
              <AssessmentOutlinedIcon
                sx={{ fontSize: 40, color: colors.snow }}
              />
            </Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 700,
                color: colors.snow,
                mb: 2,
                textShadow: '0 2px 10px rgba(0,0,0,0.2)',
              }}
            >
              Damage Assessment
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: colors.ice,
                maxWidth: '600px',
                mx: 'auto',
                fontWeight: 400,
                opacity: 0.9,
              }}
            >
              Advanced analysis platform for comprehensive disaster evaluation
            </Typography>
          </Box>

          {/* Dashboard Grid */}
          <Grid container spacing={3}>
            {/* About Section */}
            <Grid item xs={12} md={6}>
              <DashboardCard title="About the Platform">
                <Typography
                  sx={{
                    color: colors.ice,
                    lineHeight: 1.8,
                    fontSize: '1.1rem',
                    opacity: 0.9,
                  }}
                >
                  Our state-of-the-art assessment system provides comprehensive
                  analysis tools for disaster impact evaluation. Using advanced
                  methodologies and standardized protocols, we ensure accurate
                  and efficient damage assessment processes.
                </Typography>
              </DashboardCard>
            </Grid>

            {/* Event Selection */}
            <Grid item xs={12} md={6}>
              <DashboardCard title="Select Event">
                <FormControl fullWidth>
                  <InputLabel
                    id="event-select-label"
                    sx={{
                      color: colors.ice,
                      '&.Mui-focused': { color: colors.sage },
                      background: colors.onyx,
                      px: 1,
                    }}
                  >
                    Select Disaster Event
                  </InputLabel>
                  <Select
                    labelId="event-select-label"
                    value={value}
                    onChange={onChange}
                    IconComponent={ExpandMoreIcon}
                    sx={{
                      '& .MuiSelect-select': { py: 2.5 },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${colors.snow}20`,
                        borderRadius: 2,
                        borderWidth: '2px',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${colors.snow}30`,
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.sage,
                      },
                      bgcolor: `${colors.rain}50`,
                      color: colors.snow,
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: colors.onyx,
                          border: `1px solid ${colors.snow}20`,
                        },
                      },
                    }}
                  >
                    {/* Menu Items remain the same */}
                    {subtitles.map((subtitle, index) => (
                      <MenuItem
                        key={subtitle.id || index}
                        value={subtitle.name || 'unknown'}
                        sx={{
                          py: 2,
                          '&:hover': { bgcolor: `${colors.sage}20` },
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{ color: colors.snow, fontWeight: 500 }}
                          >
                            {subtitle.name || 'Unnamed Event'}
                          </Typography>
                          {subtitle.description && (
                            <Typography
                              variant="body2"
                              sx={{ color: colors.ice, mt: 0.5, opacity: 0.8 }}
                            >
                              {subtitle.description}
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DashboardCard>
            </Grid>

            {/* Features Grid */}
            <Grid item xs={12} md={6}>
              <DashboardCard title="Key Features">
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: '1fr',
                      sm: 'repeat(auto-fit, minmax(150px, 1fr))',
                    },
                    gap: 2,
                  }}
                >
                  {features.map((feature, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1,
                        p: 2,
                        borderRadius: 2,
                        background: `${colors.snow}08`,
                        border: `1px solid ${colors.snow}10`,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          background: `${colors.snow}12`,
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      <feature.icon sx={{ color: colors.sage, fontSize: 28 }} />
                      <Typography
                        sx={{ color: colors.ice, fontSize: '0.875rem' }}
                      >
                        {feature.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </DashboardCard>
            </Grid>

            {/* Partners */}
            <Grid item xs={12} md={6}>
              <DashboardCard title="Our Partners">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 4,
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      background: `${colors.snow}08`,
                      border: `1px solid ${colors.snow}10`,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        background: `${colors.snow}12`,
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    <img
                      src="/TAMUlogoWhite.png"
                      alt="TAMU Logo"
                      style={{ height: '70px', width: 'auto' }}
                    />
                  </Box>
                  <Box
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      background: `${colors.snow}08`,
                      border: `1px solid ${colors.snow}10`,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        background: `${colors.snow}12`,
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    <img
                      src="/TDISlogoWhite.png"
                      alt="TDIS Logo"
                      style={{ height: '70px', width: 'auto' }}
                    />
                  </Box>
                </Box>
              </DashboardCard>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default SelectionScreen
