import React from 'react'
import { Box, Typography, Divider, Link } from '@mui/material'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import GavelIcon from '@mui/icons-material/Gavel'
import EmailIcon from '@mui/icons-material/Email'

const FooterComponent = () => {
  const colors = {
    rain: '#324755',
    sage: '#87BCBF',
    snow: '#FFFFFF',
    ice: '#F0F3F4',
    highlight: '#f0c14b',
  }

  return (
    <footer
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        background: `linear-gradient(135deg, ${colors.rain}, #1B1C20)`,
        color: colors.snow,
        padding: '10px 20px',
        boxSizing: 'border-box',
      }}
    >
      <Divider
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          marginBottom: '10px',
        }}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '5px',
        }}
      >
        <Typography
          variant="body2"
          style={{ fontWeight: 500, fontSize: '0.8rem' }}
        >
          TDIS © 2024. All Rights Reserved.
        </Typography>

        <Box style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          <Link
            href="#"
            underline="none"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              color: colors.snow,
              fontSize: '0.8rem',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.color = colors.highlight)
            }
            onMouseLeave={(e) => (e.currentTarget.style.color = colors.snow)}
          >
            <PrivacyTipIcon fontSize="small" /> Privacy Policy
          </Link>
          <Link
            href="#"
            underline="none"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              color: colors.snow,
              fontSize: '0.8rem',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.color = colors.highlight)
            }
            onMouseLeave={(e) => (e.currentTarget.style.color = colors.snow)}
          >
            <GavelIcon fontSize="small" /> Terms of Use
          </Link>
          <Link
            href="#"
            underline="none"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              color: colors.snow,
              fontSize: '0.8rem',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.color = colors.highlight)
            }
            onMouseLeave={(e) => (e.currentTarget.style.color = colors.snow)}
          >
            <EmailIcon fontSize="small" /> Contact Us
          </Link>
        </Box>

        <Typography
          variant="body2"
          style={{
            fontStyle: 'italic',
            fontSize: '0.7rem',
            textAlign: 'center',
          }}
        >
          Empowering Data Intelligence
        </Typography>
      </Box>
    </footer>
  )
}

export default FooterComponent
