import React from 'react'
import { Box, Typography } from '@mui/material'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

const ChartWidget = ({ title, data, bgcolor }) => {
  return (
    <Box
      sx={{
        bgcolor: bgcolor || '#f9fafb', // Very light gray-white
        borderRadius: '8px',
        p: 3,
        color: '#000000', // Pure black text
        boxShadow: '0 1px 2px rgba(0,0,0,0.05)', // Very subtle shadow
        border: '1px solid rgba(0,0,0,0.03)', // Extremely subtle border
        height: '280px', // Fixed height for consistency
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        {title}
      </Typography>

      <Box sx={{ flex: 1, width: '100%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={2}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default ChartWidget
