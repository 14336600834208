import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { AuthProvider } from './contexts/AuthContext'
import store from './store'
import App from './App'

ReactDOM.render(
  <ReduxProvider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ReduxProvider>,
  document.getElementById('root')
)
