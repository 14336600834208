// components/widgets/StatWidget.js
import React from 'react'
import { Box, Typography } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

const colors = {
  snow: '#FFFFFF',
  rain: '#324755',
  whoop: '#500000',
}

const getIcon = (title) => {
  if (title === 'Structure Impacts') return HomeIcon
  if (title === 'Damage Estimate') return AttachMoneyIcon
  return null
}

const StatWidget = ({ title, value, label }) => {
  const IconComponent = getIcon(title)
  const isMoneyValue = value.includes('$')

  return (
    <Box
      sx={{
        bgcolor: colors.snow,
        borderRadius: '12px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        border: '1px solid rgba(50, 71, 85, 0.08)',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: `0 12px 24px ${colors.whoop}22`,
          '& .header-section': {
            background: `linear-gradient(45deg, ${colors.whoop}0A 0%, ${colors.whoop}1A 100%)`,
          },
          '& .icon-box': {
            transform: 'scale(1.1) rotate(5deg)',
            bgcolor: `${colors.whoop}22`,
          },
          '& .value-text': {
            transform: 'scale(1.05)',
            color: colors.whoop,
          },
          '& .highlight-bar': {
            width: '80%',
            opacity: 1,
          },
        },
      }}
    >
      {/* Decorative Corner Effects */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100px',
          height: '100px',
          background: `radial-gradient(circle at top right, ${colors.whoop}14, transparent 70%)`,
          pointerEvents: 'none',
        }}
      />

      {/* Header Section */}
      <Box
        className="header-section"
        sx={{
          p: 2,
          background:
            'linear-gradient(45deg, rgba(50, 71, 85, 0.04) 0%, rgba(50, 71, 85, 0.08) 100%)',
          borderBottom: '1px solid rgba(50, 71, 85, 0.08)',
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          transition: 'all 0.3s ease',
        }}
      >
        {IconComponent && (
          <Box
            className="icon-box"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '36px',
              height: '36px',
              borderRadius: '10px',
              bgcolor: 'rgba(50, 71, 85, 0.08)',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              transform: 'rotate(0deg)',
            }}
          >
            <IconComponent
              sx={{
                fontSize: 20,
                color: colors.rain,
                transition: 'all 0.3s ease',
              }}
            />
          </Box>
        )}
        <Typography
          variant="body2"
          sx={{
            color: colors.rain,
            fontWeight: 600,
            letterSpacing: '0.5px',
          }}
        >
          {title}
        </Typography>
      </Box>

      {/* Content Section */}
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60%',
            height: '1px',
            background:
              'linear-gradient(90deg, transparent, rgba(50, 71, 85, 0.1), transparent)',
          },
        }}
      >
        {/* Value with Glow Effect */}
        <Typography
          className="value-text"
          variant="h3"
          sx={{
            fontSize: '2.5rem',
            fontWeight: 600,
            color: colors.rain,
            mb: 1,
            textAlign: 'center',
            transition: 'all 0.3s ease',
            textShadow: `0 0 30px ${colors.whoop}00`,
            '&:hover': {
              textShadow: `0 0 30px ${colors.whoop}33`,
            },
          }}
        >
          {value}
        </Typography>

        {/* Highlight Bar */}
        <Box
          className="highlight-bar"
          sx={{
            width: '40%',
            height: '3px',
            bgcolor: colors.whoop,
            borderRadius: '2px',
            mb: 2,
            opacity: 0.7,
            transition: 'all 0.3s ease',
          }}
        />

        {/* Label */}
        <Typography
          variant="body2"
          sx={{
            color: colors.rain, // Different color to highlight
            fontWeight: 700, // Bold
            textAlign: 'center',
            position: 'relative',
            px: 2,
            py: 0.5,
            borderRadius: '4px',
            transition: 'all 0.3s ease',
            '&:hover': {
              bgcolor: 'rgba(50, 71, 85, 0.1)', // Slight hover effect
            },
          }}
        >
          {label}
        </Typography>

        {/* Bottom Decoration */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: `linear-gradient(90deg, transparent, ${colors.whoop}1A, transparent)`,
          }}
        />
      </Box>

      {/* Pulse Effect for Money Values */}
      {isMoneyValue && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            border: `2px solid ${colors.whoop}1A`,
            borderRadius: '12px',
            animation: 'pulse 2s infinite',
            '@keyframes pulse': {
              '0%': {
                transform: 'translate(-50%, -50%) scale(1)',
                opacity: 0.5,
              },
              '70%': {
                transform: 'translate(-50%, -50%) scale(1.05)',
                opacity: 0,
              },
              '100%': {
                transform: 'translate(-50%, -50%) scale(1)',
                opacity: 0,
              },
            },
          }}
        />
      )}
    </Box>
  )
}

export default StatWidget
