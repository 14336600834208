import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { fetchSubtitles } from '../actions/fetchActions'
import AppBarComponent from './AppBarComponent'
import SelectionScreen from './screens/SelectionScreen'
import DashboardScreen from './screens/DashboardScreen'
import FooterComponent from './layout/FooterComponent'
import { useAuth } from '../contexts/AuthContext'

const MapWithVariable = () => {
  const dispatch = useDispatch()
  const { keycloak } = useAuth()
  const subtitles = useSelector((state) => state.subTitles?.subtitles || [])

  // State management
  const [selectedVariable, setSelectedVariable] = useState('')
  const [selectedSubtitle, setSelectedSubtitle] = useState('Select an Event')
  const [selectedId, setSelectedId] = useState(null)

  // Fetch subtitles on component mount
  useEffect(() => {
    if (keycloak?.token) {
      dispatch(fetchSubtitles(keycloak.token))
    }
  }, [dispatch, keycloak?.token])

  // Handle dropdown selection change
  const handleSelectionChange = (e) => {
    const selectedName = e.target.value
    setSelectedVariable(selectedName)

    const selectedSubtitleObject = subtitles.find(
      (subtitle) => subtitle.name === selectedName
    )

    setSelectedSubtitle(
      selectedSubtitleObject?.description || 'Select an Event'
    )
    setSelectedId(selectedSubtitleObject?.id || null)
  }

  // Handle back button click
  const handleBackButtonClick = () => {
    console.log('Back button clicked') // Debug log
    setSelectedVariable('')
    setSelectedSubtitle('Select an Event')
    setSelectedId(null)
  }

  // Handle logout
  const handleLogout = () => {
    if (keycloak?.logout) {
      keycloak.logout()
    }
  }

  // If no authentication, show loading
  if (!keycloak?.token) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          bgcolor: '#f5f5f5',
        }}
      ></Box>
    )
  }

  // Render selection screen if no event is selected
  if (!selectedId) {
    return (
      <SelectionScreen
        value={selectedVariable}
        onChange={handleSelectionChange}
        subtitles={subtitles}
      />
    )
  }

  // Render dashboard with selected event
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#f5f5f5',
      }}
    >
      <AppBarComponent
        subtitle={selectedSubtitle}
        username={keycloak?.tokenParsed?.preferred_username}
        onLogout={handleLogout}
        onBack={handleBackButtonClick}
      />
      <Box sx={{ flex: 1, mt: '80px', mb: '64px' }}>
        <DashboardScreen selectedId={selectedId} token={keycloak?.token} />
      </Box>
      <FooterComponent />
    </Box>
  )
}

export default MapWithVariable
