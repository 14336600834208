import cubejs from '@cubejs-client/core'
import cubeConfig from './cubeConfig.json'

const CUBEJS_API_URL = process.env.REACT_APP_CUBE_API_HOST

export function fetchCubeData(functionalityName, commonFilters = [], token) {
  try {
    const cubejsApi = cubejs(token, {
      apiUrl: `${CUBEJS_API_URL}/cubejs-api/v1`,
    })

    const queryConfig = cubeConfig[functionalityName]

    if (!queryConfig) {
      throw new Error(
        `No query configuration found for functionality: ${functionalityName}`
      )
    }

    const query = {
      measures: queryConfig.measures || [],
      dimensions: queryConfig.dimensions || [],
      timeDimensions: queryConfig.timeDimension
        ? [
            {
              dimension: queryConfig.timeDimension,
              granularity: queryConfig.granularity || null,
            },
          ]
        : [],
      filters: [...(queryConfig.filters || []), ...commonFilters],
      order: queryConfig.order || [],
    }

    return cubejsApi.load(query)
  } catch (error) {
    console.error('Error executing Cube.js query:', error)
    throw error
  }
}
