import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import KeplerGl from 'kepler.gl'
import { fetchHarrisCounty } from '../actions/fetchActions'
import { AutoSizer } from 'react-virtualized'
import { resetMapConfig } from 'kepler.gl/actions'

const Map = ({ variable, token }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetMapConfig())
    dispatch(fetchHarrisCounty({ variable, token }))
  }, [dispatch, variable, token])

  return (
    <AutoSizer>
      {({ height, width }) => (
        <KeplerGl
          id="flood_zones"
          width={width}
          height={height}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          theme="base"
        />
      )}
    </AutoSizer>
  )
}

export default Map
