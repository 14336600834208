const initialState = {
  subtitles: [],
  loading: false,
  error: null,
}

const subtitlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SUBTITLES_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'FETCH_SUBTITLES_SUCCESS':
      return {
        ...state,
        loading: false,
        subtitles: action.payload, // Update subtitles with fetched data
      }
    case 'FETCH_SUBTITLES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default subtitlesReducer
