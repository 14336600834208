import React from 'react'
import { Box, Button } from '@mui/material'
import StatWidget from '../widgets/StatWidget'
import ChartWidget from '../widgets/ChartWidget'
import Map from '../Map'
import { useAuth } from '../../contexts/AuthContext'

const chartData = {
  occupancyData: [
    { name: 'Residential', value: 14550, color: '#FFA500' },
    { name: 'Commercial', value: 2564, color: '#00CED1' },
    { name: 'Industrial', value: 755, color: '#48D1CC' },
    { name: 'Manufactured', value: 1620, color: '#20B2AA' },
  ],
  severityData: [
    { name: 'Affected', value: 11724, color: '#0000FF' },
    { name: 'Destroyed', value: 3527, color: '#FF0000' },
    { name: 'Major', value: 4292, color: '#008000' },
    { name: 'Minor', value: 946, color: '#FFFF00' },
  ],
}

const DashboardScreen = ({ selectedId, onBack }) => {
  const { keycloak } = useAuth()
  const { occupancyData, severityData } = chartData
  const widgetBgColor = '#f7f7f7'

  if (!keycloak?.token) {
    return <Box>Loading authentication...</Box>
  }

  return (
    <Box
      sx={{
        height: 'calc(100vh - 160px)',
        position: 'fixed',
        top: '80px',
        left: 0,
        right: 0,
        bottom: '64px',
        bgcolor: '#e6e8eb',
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          gap: '12px',
          p: 2,
          position: 'relative',
        }}
      >
        {/* Left Panel - Fixed size for widgets */}
        <Box
          sx={{
            width: '330px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            pr: 2,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'rgba(0,0,0,0.03)',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(0,0,0,0.1)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'rgba(0,0,0,0.15)',
            },
          }}
        >
          <StatWidget
            title="Structure Impacts"
            value="19,489"
            label="Structures"
            bgcolor={widgetBgColor}
            token={keycloak.token}
          />
          <StatWidget
            title="Damage Estimate"
            value="$1.93B"
            label="Total"
            bgcolor={widgetBgColor}
            token={keycloak.token}
          />
          <ChartWidget
            title="Damage Severity Distribution"
            data={severityData}
            bgcolor={widgetBgColor}
            token={keycloak.token}
          />
          <ChartWidget
            title="Occupancy Type Distribution"
            data={occupancyData}
            bgcolor={widgetBgColor}
            token={keycloak.token}
          />
        </Box>

        {/* Map Widget Container */}
        <Box
          sx={{
            flex: 1,
            bgcolor: '#f7f7f7',
            borderRadius: '8px',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
            border: '1px solid rgba(0,0,0,0.03)',
          }}
        >
          <Box
            sx={{
              flex: 1,
              position: 'relative',
              '& > div': {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
            }}
          >
            <Map variable={selectedId} token={keycloak.token} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardScreen
