import React from 'react'
import {
  Container,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  useTheme,
} from '@mui/material'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'
import { GlobalStyles } from '@mui/material'

const SelectionScreen = ({ value, onChange, subtitles = [] }) => {
  const theme = useTheme()

  // IDRT Colors
  const colors = {
    slate: '#6E8CA0',
    whoop: '#500000',
    rain: '#324755',
    sage: '#87BCBF',
    snow: '#FFFFFF',
    onyx: '#1B1C20',
    sunshine: '#FCC931',
    ice: '#F0F3F4',
    sand: '#B9B0A2',
  }

  const features = [
    { icon: AnalyticsOutlinedIcon, label: 'Advanced Analytics' },
    { icon: SecurityOutlinedIcon, label: 'Secure Assessment' },
    { icon: SpeedOutlinedIcon, label: 'Real-time Processing' },
  ]

  return (
    <>
      <GlobalStyles
        styles={{
          html: {
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          },
          body: {
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            fontFamily: 'Roboto, sans-serif',
          },
          '#root': {
            width: '100%',
            height: '100%',
          },
        }}
      />
      <Box
        sx={{
          minHeight: '100vh',
          background: colors.rain,
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          margin: 0,
          boxSizing: 'border-box',
        }}
      >
        {/* Animated Background Elements */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            opacity: 0.7,
            background: `linear-gradient(135deg, ${colors.rain} 0%, ${colors.onyx} 100%)`,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '5%',
              left: '5%',
              width: '40%',
              height: '40%',
              background: `radial-gradient(circle, ${colors.sage}20 0%, transparent 70%)`,
              filter: 'blur(50px)',
              animation: 'pulse 8s ease-in-out infinite',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '5%',
              right: '5%',
              width: '40%',
              height: '40%',
              background: `radial-gradient(circle, ${colors.whoop}20 0%, transparent 70%)`,
              filter: 'blur(50px)',
              animation: 'pulse 8s ease-in-out infinite alternate',
            },
            '@keyframes pulse': {
              '0%': { opacity: 0.5, transform: 'scale(1)' },
              '50%': { opacity: 0.8, transform: 'scale(1.2)' },
              '100%': { opacity: 0.5, transform: 'scale(1)' },
            },
          }}
        />

        <Container
          maxWidth="lg"
          sx={{ position: 'relative', py: 6, overflow: 'hidden', padding: 0 }}
        >
          {' '}
          {/* Remove Padding */}
          <Box sx={{ maxWidth: '900px', mx: 'auto' }}>
            {/* Header Section */}
            <Box sx={{ textAlign: 'center', mb: 8 }}>
              <Box
                sx={{
                  display: 'inline-flex',
                  p: 2.5,
                  borderRadius: '30px',
                  background: `linear-gradient(135deg, ${colors.slate}30, ${colors.whoop}20)`,
                  border: `1px solid ${colors.snow}15`,
                  mb: 4,
                  transform: 'translateY(0px)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    background: `linear-gradient(135deg, ${colors.slate}40, ${colors.whoop}30)`,
                  },
                }}
              >
                <AssessmentOutlinedIcon
                  sx={{
                    fontSize: 48,
                    color: colors.snow,
                  }}
                />
              </Box>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  color: colors.snow,
                  mb: 3,
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  textShadow: '0 2px 10px rgba(0,0,0,0.2)',
                }}
              >
                Damage Assessment
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: colors.ice,
                  maxWidth: '600px',
                  mx: 'auto',
                  mb: 2,
                  fontWeight: 400,
                  opacity: 0.9,
                }}
              >
                Advanced analysis platform for comprehensive disaster evaluation
              </Typography>
            </Box>

            {/* Logos Section */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 6,
                mb: 4,
              }}
            >
              <img
                src="/TAMUlogoWhite.png"
                alt="TAMU Logo"
                style={{ height: '100px', width: 'auto' }}
              />
              <img
                src="/TDISlogoWhite.png"
                alt="TDIS Logo"
                style={{ height: '100px', width: 'auto' }}
              />
            </Box>

            {/* Main Card */}
            <Paper
              elevation={0}
              sx={{
                borderRadius: 4,
                overflow: 'hidden',
                background: `${colors.onyx}95`,
                backdropFilter: 'blur(20px)',
                border: `1px solid ${colors.snow}15`,
                transition: 'transform 0.3s ease',
              }}
            >
              {/* Content Section */}
              <Box sx={{ p: { xs: 3, md: 5 }, height: '100%' }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: colors.snow,
                    mb: 3,
                    fontWeight: 500,
                  }}
                >
                  About the Platform
                </Typography>
                <Typography
                  sx={{
                    color: colors.ice,
                    lineHeight: 1.8,
                    mb: 5,
                    fontSize: '1.1rem',
                    opacity: 0.9,
                  }}
                >
                  Our state-of-the-art assessment system provides comprehensive
                  analysis tools for disaster impact evaluation. Using advanced
                  methodologies and standardized protocols, we ensure accurate
                  and efficient damage assessment processes.
                </Typography>

                {/* Selection Control */}
                <FormControl fullWidth>
                  <InputLabel
                    id="event-select-label"
                    sx={{
                      color: colors.ice,
                      '&.Mui-focused': {
                        color: colors.sage,
                      },
                      background: colors.onyx,
                      px: 1,
                    }}
                  >
                    Select Disaster Event
                  </InputLabel>
                  <Select
                    labelId="event-select-label"
                    value={value}
                    onChange={onChange}
                    IconComponent={ExpandMoreIcon}
                    sx={{
                      '& .MuiSelect-select': {
                        py: 2.5,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${colors.snow}20`,
                        borderRadius: 2,
                        borderWidth: '2px',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${colors.snow}20`,
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.sage,
                      },
                      '& .MuiSelect-icon': {
                        color: colors.sage,
                        transition: 'transform 0.2s',
                      },
                      '&.Mui-focused .MuiSelect-icon': {
                        transform: 'rotate(180deg)',
                      },
                      bgcolor: `${colors.rain}50`,
                      color: colors.snow,
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: colors.onyx,
                          backgroundImage: 'none',
                          border: `1px solid ${colors.snow}20`,
                          maxHeight: '400px',
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <Box sx={{ py: 1 }}>
                        {subtitles.length > 0 ? (
                          <Typography sx={{ color: colors.ice }}>
                            Choose an event to proceed
                          </Typography>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                width: 20,
                                height: 20,
                                borderRadius: '50%',
                                border: `2px solid ${colors.sage}30`,
                                borderTopColor: colors.sage,
                                animation: 'spin 1s linear infinite',
                                '@keyframes spin': {
                                  '0%': { transform: 'rotate(0deg)' },
                                  '100%': { transform: 'rotate(360deg)' },
                                },
                              }}
                            />
                            <Typography sx={{ color: colors.ice }}>
                              Loading available events...
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </MenuItem>
                    {subtitles.map((subtitle, index) => (
                      <MenuItem
                        key={subtitle.id || index}
                        value={subtitle.name || 'unknown'}
                        sx={{
                          py: 2,
                          transition: 'all 0.2s',
                          '&:hover': {
                            bgcolor: `${colors.sage}20`,
                          },
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{ color: colors.snow, fontWeight: 500 }}
                          >
                            {subtitle.name || 'Unnamed Event'}
                          </Typography>
                          {subtitle.description && (
                            <Typography
                              variant="body2"
                              sx={{
                                color: colors.ice,
                                mt: 0.5,
                                opacity: 0.8,
                              }}
                            >
                              {subtitle.description}
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Feature Icons */}
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'center',
                    mt: 5,
                    flexWrap: 'wrap',
                  }}
                >
                  {features.map((feature, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1,
                        p: 2,
                        borderRadius: 2,
                        background: `${colors.snow}08`,
                        border: `1px solid ${colors.snow}10`,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          background: `${colors.snow}12`,
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      <feature.icon sx={{ color: colors.sage, fontSize: 28 }} />
                      <Typography
                        sx={{ color: colors.ice, fontSize: '0.875rem' }}
                      >
                        {feature.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default SelectionScreen
