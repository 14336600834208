import { createStore, combineReducers, applyMiddleware } from 'redux'
import { taskMiddleware } from 'react-palm/tasks' // Middleware for Kepler.gl
import { thunk } from 'redux-thunk' // Middleware for async actions like Cube.js
import keplerGlReducer from 'kepler.gl/reducers'
import appReducer from './reducers/mapReducer'
import subtitlesReducer from './reducers/subtitlesReducer'

// Combine reducers (add other reducers if needed)
const reducers = combineReducers({
  keplerGl: keplerGlReducer, // Kepler.gl reducer
  app: appReducer,
  subTitles: subtitlesReducer,
})

// Apply both redux-thunk and taskMiddleware
const store = createStore(
  reducers,
  {},
  applyMiddleware(thunk, taskMiddleware) // Apply redux-thunk and taskMiddleware
)

export default store
