import Keycloak from 'keycloak-js'

const keycloakConfig = {
  url: 'https://tdis-keycloak-authentication-budtf2b6akfzg4ek.southcentralus-01.azurewebsites.net',
  realm: 'Damage-Assessment',
  clientId: '8c592401-43f0-4a3b-b977-db79aaebb6e2',
}

const keycloak = new Keycloak(keycloakConfig)
export default keycloak
