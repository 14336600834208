import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'
import StatWidget from '../widgets/StatWidget'
import ChartWidget from '../widgets/ChartWidget'
import Map from '../Map'
import { useAuth } from '../../contexts/AuthContext'
import { fetchStructureCount } from '../../actions/fetchActions'

const chartData = {
  occupancyData: [
    { name: 'Residential', value: 14550, color: '#FFA500' },
    { name: 'Commercial', value: 2564, color: '#00CED1' },
    { name: 'Industrial', value: 755, color: '#48D1CC' },
    { name: 'Manufactured', value: 1620, color: '#20B2AA' },
  ],
  severityData: [
    { name: 'Affected', value: 11724, color: '#4169E1' },
    { name: 'Destroyed', value: 3527, color: '#DC3545' },
    { name: 'Major', value: 4292, color: '#28A745' },
    { name: 'Minor', value: 946, color: '#FFC107' },
  ],
}

const DashboardScreen = ({ selectedId, onBack }) => {
  const { keycloak } = useAuth()
  const { occupancyData, severityData } = chartData
  const dispatch = useDispatch()
  const [structureCount, setStructureCount] = useState(null) // State to store the fetched structure count
  const [loading, setLoading] = useState(true) // State to handle loading

  useEffect(() => {
    if (keycloak?.token && selectedId) {
      const fetchData = async () => {
        setLoading(true)
        try {
          const count = await dispatch(
            fetchStructureCount(selectedId, keycloak.token)
          )
          setStructureCount(count)
        } catch (error) {
          console.error('Error fetching structure count:', error)
          setStructureCount('Error')
        } finally {
          setLoading(false)
        }
      }

      fetchData()
    }
  }, [dispatch, keycloak?.token, selectedId])
  if (!keycloak?.token) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          bgcolor: '#f5f5f5',
        }}
      >
        <Typography>Loading authentication...</Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '80px',
        bottom: '64px',
        left: 0,
        right: 0,
        bgcolor: '#f5f5f5',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          p: 2,
          gap: 2,
        }}
      >
        {/* Left Panel - Structure Impacts and Occupancy Type */}
        <Box
          sx={{
            width: '280px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              p: 3,
              flex: 1,
              borderRadius: 2,
              bgcolor: 'white',
              border: '1px solid #e0e0e0',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              },
            }}
          >
            <StatWidget
              title="Structure Impacts"
              value={loading ? 'Loading...' : structureCount} // Show loading or fetched data
              label="Structures"
              token={keycloak.token}
            />
          </Paper>

          <Paper
            elevation={0}
            sx={{
              p: 3,
              flex: 1,
              borderRadius: 2,
              bgcolor: 'white',
              border: '1px solid #e0e0e0',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              },
            }}
          >
            <ChartWidget
              title="Occupancy Type Distribution"
              data={occupancyData}
              token={keycloak.token}
            />
          </Paper>
        </Box>

        {/* Map Container */}
        <Paper
          elevation={0}
          sx={{
            flex: 1,
            borderRadius: 2,
            bgcolor: 'white',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            '& > div': {
              height: '100%',
            },
          }}
        >
          <Map variable={selectedId} token={keycloak.token} />
        </Paper>

        {/* Right Panel - Damage Estimate and Severity */}
        <Box
          sx={{
            width: '280px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              p: 3,
              flex: 1,
              borderRadius: 2,
              bgcolor: 'white',
              border: '1px solid #e0e0e0',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              },
            }}
          >
            <StatWidget
              title="Damage Estimate"
              value="$1.93B"
              label="Total"
              token={keycloak.token}
            />
          </Paper>

          <Paper
            elevation={0}
            sx={{
              p: 3,
              flex: 1,
              borderRadius: 2,
              bgcolor: 'white',
              border: '1px solid #e0e0e0',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              },
            }}
          >
            <ChartWidget
              title="Damage Severity Distribution"
              data={severityData}
              token={keycloak.token}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardScreen
