import React from 'react'
import { Box, Typography } from '@mui/material'

const StatWidget = ({
  title,
  value,
  label,
  bgcolor = 'rgba(255,255,255,0.05)',
}) => {
  return (
    <Box
      sx={{
        bgcolor: bgcolor || '#f9fafb', // Very light gray-white
        borderRadius: '8px',
        p: 3,
        color: '#000000', // Pure black text
        boxShadow: '0 1px 2px rgba(0,0,0,0.05)', // Very subtle shadow
        border: '1px solid rgba(0,0,0,0.03)', // Extremely subtle border
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: 1, opacity: 0.7 }}>
        {title}
      </Typography>
      <Typography variant="h4" sx={{ mb: 0.5 }}>
        {value}
      </Typography>
      <Typography variant="body2" sx={{ opacity: 0.7 }}>
        {label}
      </Typography>
    </Box>
  )
}

export default StatWidget
