import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import MapWithVariable from './components/MapWithVariable'
function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<MapWithVariable />} />
        </Routes>
      </ErrorBoundary>
    </Router>
  )
}

export default App
