import React, { createContext, useContext, useState } from 'react'
import keycloak from '../keycloak'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false)

  // Initialize Keycloak only once when component mounts
  if (!initialized) {
    keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
      })
      .then(() => {
        setInitialized(true)
      })
      .catch((error) => {
        console.error('Keycloak initialization error:', error)
        setInitialized(true)
      })
  }

  if (!initialized) {
    return <div>Loading...</div>
  }

  return (
    <AuthContext.Provider value={{ keycloak, initialized }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
