import React from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import ImgAsset from '../public'

const AppBarComponent = ({ subtitle = '', onLogout, onBack }) => {
  const colors = {
    rain: '#324755',
    sage: '#87BCBF',
    snow: '#FFFFFF',
    ice: '#F0F3F4',
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: colors.rain,
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.4)',
        px: 3,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Left Section: Logos */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="TDIS Logo"
            sx={{
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              },
            }}
          >
            <img
              src={ImgAsset.TDISlogoWhite}
              alt="TDIS Logo"
              style={{ width: '140px', height: '50px' }}
            />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="TAMU Logo"
            sx={{
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              },
            }}
          >
            <img
              src={ImgAsset.TAMUlogoWhite}
              alt="TAMU Logo"
              style={{ width: '140px', height: '50px' }}
            />
          </IconButton>
        </Box>

        {/* Center Section: Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: colors.snow,
            letterSpacing: '2px',
            textTransform: 'uppercase',
            textShadow: '0 3px 15px rgba(0, 0, 0, 0.5)',
          }}
        >
          <span style={{ color: colors.sage, fontSize: '1.5em' }}>D</span>
          amage <span style={{ color: colors.sage, fontSize: '1.5em' }}>A</span>
          ssessment {subtitle && `- ${subtitle}`}
        </Typography>

        {/* Right Section: Buttons */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Back to Selection Button */}
          <Tooltip title="Back to Selection">
            <IconButton
              onClick={onBack}
              sx={{
                backgroundColor: colors.sage,
                color: colors.rain,
                fontSize: '1.2rem',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: '#76B2AC',
                  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>

          {/* Logout Icon */}
          <Tooltip title="Logout">
            <IconButton
              onClick={onLogout}
              sx={{
                backgroundColor: colors.sage,
                color: colors.rain,
                fontSize: '1.2rem',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: '#76B2AC',
                  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              <PowerSettingsNewIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default AppBarComponent
