import { addDataToMap } from 'kepler.gl/actions'
import { processRowObject } from 'kepler.gl/processors'
import { fetchCubeData } from '../services/cubeQuery'
import config from './config.json'

export const fetchHarrisCounty =
  ({ variable, token }) =>
  async (dispatch) => {
    try {
      const commonFilters = [
        {
          dimension: 'IncidentDamageAssessment.incidentId',
          operator: 'equals',
          values: [variable],
        },
      ]

      const structureFilters = [
        {
          dimension: 'StructureDamage.incidentId', // Correct dimension
          operator: 'equals',
          values: [variable], // Ensure 'variable' has the correct incident ID
        },
      ];

      const [countyData, destroyed, major, minor, moderate, structure] =
        await Promise.all([
          fetchCubeData('HARRIS_COUNTY', [], token),
          fetchCubeData('DESTROYED_DATA', commonFilters, token),
          fetchCubeData('MAJOR_DATA', commonFilters, token),
          fetchCubeData('MINOR_DATA', commonFilters, token),
          fetchCubeData('MODERATE_DATA', commonFilters, token),
          fetchCubeData('STRUCTURE_DATA', structureFilters, token),
        ])

      const processedCountyData = processRowObject(countyData.rawData())

      const processedStructureData = processRowObject(structure.rawData())

      // Process damage data using damageCategoryDescription
      const processDataWithDamageLevel = (data, damageLevel) => {
        const rawData = data.rawData()
        const processed = processRowObject(rawData)
        return processed.rows.map((row) => ({
          ...row,
          'IncidentDamageAssessment.damageCategoryDescription': damageLevel,
        }))
      }

      const damageData = {
        fields: [
          ...processRowObject(destroyed.rawData()).fields,
          {
            name: 'IncidentDamageAssessment.damageCategoryDescription',
            type: 'string',
          },
        ],
        rows: [
          ...processDataWithDamageLevel(destroyed, 'DESTROYED'),
          ...processDataWithDamageLevel(major, 'MAJOR'),
          ...processDataWithDamageLevel(minor, 'MINOR'),
          ...processDataWithDamageLevel(moderate, 'MODERATE'),
        ],
      }

      dispatch(
        addDataToMap({
          datasets: [
            {
              info: {
                id: 'county_data',
                label: 'Harris County',
              },
              data: processedCountyData,
            },
            {
              info: {
                id: 'damage_assessment_data',
                label: 'Damage Assessment',
              },
              data: damageData,
            },
            {
              info: {
                id: 'structure_data',
                label: 'Structures',
              },
              data: processedStructureData,
            },
          ],
          options: {
            centerMap: true,
            readOnly: false,
          },
          config: {
            version: 'v1',
            config: {
              mapStyle: {
                styleType: 'light',
              },
              visState: {
                layers: [
                  {
                    id: 'county-layer',
                    type: 'geojson',
                    config: {
                      dataId: 'county_data',
                      label: 'Harris County',
                      columns: {
                        geojson: 'AssessmentArea.assessmentAreaGeometry',
                      },
                      isVisible: true,
                      visConfig: {
                        opacity: 0.5,
                        strokeColor: [151, 14, 45],
                        thickness: 1,
                        filled: false,
                      },
                    },
                  },
                  {
                    id: 'damage-assessment-layer',
                    type: 'geojson',
                    config: {
                      dataId: 'damage_assessment_data',
                      label: 'Damage Assessment',
                      columns: {
                        geojson:
                          'IncidentDamageAssessment.damageAssessmentGeometry',
                      },
                      isVisible: true,
                      filled: true,
                      colorField: {
                        name: 'IncidentDamageAssessment.damageCategoryDescription',
                        type: 'string',
                      },
                      colorScale: 'ordinal',
                      visConfig: {
                        filled: true,
                        fillColor: [255, 0, 0],
                        stroked: true,
                        opacity: 0.8,
                        strokeOpacity: 1,
                        thickness: 1,
                        strokeColorField: {
                          name: 'IncidentDamageAssessment.damageCategoryDescription',
                          type: 'string',
                        },
                        strokeColorScale: 'ordinal',
                        strokeColorRange: {
                          name: 'Custom',
                          type: 'custom',
                          category: 'Custom',
                          colors: ['#FF0000', '#FFA500', '#FFFF00', '#32CD32'], // Match stroke with fill
                          steps: 4,
                        },
                        colorRange: {
                          name: 'Custom',
                          type: 'custom',
                          category: 'Custom',
                          colors: ['#FF0000', '#FFA500', '#FFFF00', '#32CD32'], // HEX colors
                          steps: 4,
                        },
                        fixedRadius: true,
                        radiusRange: [0, 50],
                        enable3d: false,
                      },
                    },
                    visualChannels: {
                      colorField: {
                        name: 'IncidentDamageAssessment.damageCategoryDescription',
                        type: 'string',
                        domain: ['DESTROYED', 'MAJOR', 'MINOR', 'MODERATE'],
                      },
                      colorScale: 'ordinal',
                      strokeColorField: {
                        name: 'IncidentDamageAssessment.damageCategoryDescription',
                        type: 'string',
                        domain: ['DESTROYED', 'MAJOR', 'MINOR', 'MODERATE'],
                      },
                      strokeColorScale: 'ordinal',
                    },
                  },
                  {
                    id: 'structure-layer',
                    type: 'point',
                    config: {
                      dataId: 'structure_data',
                      label: 'Structures',
                      columns: {
                        lat: 'StructureDamage.latitude',
                        lng: 'StructureDamage.longitude',
                      },
                      isVisible: true,
                      visConfig: {
                        radius: 5,
                        fixedRadius: false,
                        opacity: 0.8,
                        outline: true,
                        thickness: 2,
                        stroked: true, // Ensures stroke is applied
                        filled: true, // Ensures fill color is applied
                        colorField: {
                          name: 'StructureDamage.damageCategoryDescription',
                          type: 'string',
                        },
                        colorScale: 'ordinal',
                        colorRange: {
                          name: 'Custom',
                          type: 'custom',
                          category: 'Custom',
                          colors: ['#FF0000', '#FFA500', '#FFFF00', '#32CD32'], // Matches Damage Assessment layer
                          steps: 4,
                        },
                        strokeColorField: {
                          name: 'StructureDamage.damageCategoryDescription',
                          type: 'string',
                        },
                        strokeColorScale: 'ordinal',
                        strokeColorRange: {
                          name: 'Custom',
                          type: 'custom',
                          category: 'Custom',
                          colors: ['#FF0000', '#FFA500', '#FFFF00', '#32CD32'], // Matches Damage Assessment layer
                          steps: 4,
                        },
                      },
                    },
                    visualChannels: {
                      colorField: {
                        name: 'StructureDamage.damageCategoryDescription',
                        type: 'string',
                        domain: ['DESTROYED', 'MAJOR', 'MINOR', 'MODERATE'],
                      },
                      colorScale: 'ordinal',
                      strokeColorField: {
                        name: 'StructureDamage.damageCategoryDescription',
                        type: 'string',
                        domain: ['DESTROYED', 'MAJOR', 'MINOR', 'MODERATE'],
                      },
                      strokeColorScale: 'ordinal',
                    },
                  },
                ],
                interactionConfig: {
                  tooltip: {
                    enabled: true,
                    fieldsToShow: {
                      damage_assessment_data: [
                        'IncidentDamageAssessment.damageCategoryDescription',
                      ],
                    },
                  },
                },
              },
            },
          },
        })
      )
    } catch (error) {
      console.error('Error fetching data from Cube.js:', error)
      throw error
    }
  }
export const fetchSubtitles = (token) => async (dispatch) => {
  // Add token parameter here
  try {
    // Fetch data using fetchCubeData
    const incidentData = await fetchCubeData('INCIDENT', [], token)
    const rawData = processRowObject(incidentData.rawData())

    // Ensure rawData contains fields and rows
    if (!rawData || !rawData.fields || !rawData.rows) {
      throw new Error('Invalid data structure returned from rawData()')
    }

    const { rows, fields } = rawData

    // Map fields to their indices for dynamic processing
    const fieldIndexMap = fields.reduce((acc, field, idx) => {
      acc[field.name] = idx
      return acc
    }, {})

    // Process the rows into the desired format
    const processedSubtitles = rows.map((row) => ({
      name: row[fieldIndexMap['Incident.incidentName']] || 'Unknown Name', // Access incidentName using index
      description:
        row[fieldIndexMap['Incident.incidentDescription']] || 'No Description', // Access incidentDescription using index
      id: row[fieldIndexMap['Incident.incidentId']] || 'No Id',
    }))
    console.log(
      'Processed processedSubtitles:',
      JSON.stringify(processedSubtitles)
    )
    // Dispatch the processed data to Redux store
    dispatch({
      type: 'FETCH_SUBTITLES_SUCCESS',
      payload: processedSubtitles,
    })
  } catch (error) {
    console.error('Error fetching subtitles:', error.message)
    dispatch({
      type: 'FETCH_SUBTITLES_ERROR',
      error: error.message,
    })
  }
}
