import React from 'react'
import { Box, Typography } from '@mui/material'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { InsertChartOutlined } from '@mui/icons-material'

const colors = {
  snow: '#FFFFFF',
  rain: '#324755',
  whoop: '#500000',
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          bgcolor: 'white',
          p: 1,
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: colors.rain, fontWeight: 500 }}
        >
          {payload[0].name}
        </Typography>
        <Typography variant="body2" sx={{ color: '#666' }}>
          {payload[0].value.toLocaleString()}
        </Typography>
      </Box>
    )
  }
  return null
}

const CustomLegend = ({ data }) => {
  const total = data.reduce((sum, item) => sum + item.value, 0)

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        justifyContent: 'center',
        width: '100%',
        pb: 1, // Add padding at bottom
      }}
    >
      {data.map((entry, index) => {
        const percentage = ((entry.value / total) * 100).toFixed(1)
        return (
          <Box
            key={`legend-${index}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              minWidth: '45%',
              mb: 1, // Add margin bottom to each legend item
            }}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: entry.color,
                flexShrink: 0,
              }}
            />
            <Typography
              variant="caption"
              sx={{
                color: colors.rain,
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                whiteSpace: 'nowrap',
                fontSize: '0.75rem',
              }}
            >
              {entry.name}
              <span style={{ color: '#888' }}>({percentage}%)</span>
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

const ChartWidget = ({ title, data }) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        '&:hover': {
          '& .header-section': {
            background: `linear-gradient(45deg, ${colors.whoop}0A 0%, ${colors.whoop}1A 100%)`,
          },
          '& .icon-box': {
            transform: 'scale(1.1) rotate(5deg)',
            bgcolor: `${colors.whoop}22`,
          },
        },
      }}
    >
      {/* Header Section */}
      <Box
        className="header-section"
        sx={{
          p: 2,
          background:
            'linear-gradient(45deg, rgba(50, 71, 85, 0.04) 0%, rgba(50, 71, 85, 0.08) 100%)',
          borderBottom: '1px solid rgba(50, 71, 85, 0.08)',
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          transition: 'all 0.3s ease',
        }}
      >
        <Box
          className="icon-box"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '36px',
            height: '36px',
            borderRadius: '10px',
            bgcolor: 'rgba(50, 71, 85, 0.08)',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            transform: 'rotate(0deg)',
          }}
        >
          <InsertChartOutlined
            sx={{
              fontSize: 20,
              color: colors.rain,
              transition: 'all 0.3s ease',
            }}
          />
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: colors.rain,
            fontWeight: 600,
            letterSpacing: '0.5px',
          }}
        >
          {title}
        </Typography>
      </Box>

      {/* Chart Section */}
      <Box
        sx={{
          flex: '0 0 160px', // Fixed height for chart
          width: '100%',
          p: 2,
        }}
      >
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={60}
              paddingAngle={2}
              dataKey="value"
              strokeWidth={0}
              startAngle={90}
              endAngle={-270}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}
                  style={{ outline: 'none' }}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} cursor={false} />
          </PieChart>
        </ResponsiveContainer>
      </Box>

      {/* Legend Section */}
      <Box
        sx={{
          p: 2,
          borderTop: '1px solid rgba(50, 71, 85, 0.08)',
          flex: '0 0 auto', // Don't allow flex to shrink this section
        }}
      >
        <CustomLegend data={data} />
      </Box>

      {/* Decorative Corner Effects */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100px',
          height: '100px',
          background: `radial-gradient(circle at top right, ${colors.whoop}14, transparent 70%)`,
          pointerEvents: 'none',
        }}
      />
    </Box>
  )
}

export default ChartWidget
